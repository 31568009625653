<template>
  <div class="flex items-center justify-center sm:relative">
    <img
      src="@/assets/star.svg"
      alt="Star"
      class="hidden h-14 w-14 sm:absolute sm:-left-16 sm:top-14 sm:block"
    />

    <div class="sm:pt-8">
      <a href="https://tastenetwork.io/">
        <img
          src="@/assets/taste-network-logo.svg"
          alt="Taste Network Logo"
          class="w-44 sm:w-52"
        />
      </a>
    </div>

    <img
      src="@/assets/star.svg"
      alt="Star"
      class="hidden sm:absolute sm:-right-12 sm:top-[150px] sm:block"
    />
  </div>
</template>
